// import React from "react";
// import Slide from "../../Components/Slide/Slide.js";
import style from "./neo_modul_004_2409.module.scss";
import cn from "classnames";

const Slide_4 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;

  return (
    <div className={cn(style.neo_modul_004_2409)}>
      <div className={style.scaleWrapper}>
        <div className={style.shadow} />
        <div className={cn(style.logo_who, "fadeIn dur800 del800")} />
        <div className={cn(style.quotes, "fadeIn dur800 del400")} />
        <div className={cn(style.message_txt, "fadeInRight dur800 del600")} />
        <div className={cn(style.title, "fadeInDown dur1000 del600")} />
        <div className={style.timeline} />
        <div className={style.note} />
        <button className={style.btn_go_on} onClick={goNextSlide} />
        <button className={style.btn_back_on} onClick={goPrevSlide} />
      </div>
    </div>
  );
};

export default Slide_4;
