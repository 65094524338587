import style from "./neo_modul_010_2409.module.scss";
import { useState } from "react";
import cn from "classnames";

const Slide_10 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slideState, setSlideState] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  });
  const [goNextActive, setGoNextActive] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  });
  return (
    <div className={cn(style.neo_modul_010_2409)}>
      <div className={style.scaleWrapper}>
        {slideState[7] ? (
          <>
            <div className={style.fade_deep6}>
              <button
                className={style.btn_close}
                onClick={() => {
                  setSlideState({ ...slideState, 7: false });
                }}
              />
            </div>
            <div className={cn(style.bt7_txt, "fadeInDown dur800")} />
          </>
        ) : (
          <>
            <button
              className={style.btn_7}
              onClick={() => {
                setSlideState({ ...slideState, 7: true });
                setGoNextActive({ ...goNextActive, 7: true });
              }}
            >
              <div className={style.btn_7_img} />
              <div className={style.cross6} />
            </button>
          </>
        )}
        {slideState[6] ? (
          <>
            <div className={style.fade_deep5}>
              <button
                className={style.btn_close}
                onClick={() => setSlideState({ ...slideState, 6: false })}
              />
            </div>
            <div className={cn(style.bt6_txt, "fadeInDown dur800")} />
          </>
        ) : (
          <>
            <button
              className={style.btn_6}
              onClick={() => {
                setSlideState({ ...slideState, 6: true });
                setGoNextActive({ ...goNextActive, 6: true });
              }}
            >
              <div className={style.btn_6_img} />
              <div className={style.cross5} />
            </button>
          </>
        )}
        {slideState[5] ? (
          <>
            <div className={style.fade_deep4}>
              <button
                className={style.btn_close}
                onClick={() => setSlideState({ ...slideState, 5: false })}
              />
            </div>
            <div className={cn(style.bt5_txt, "fadeInDown dur800")} />
          </>
        ) : (
          <>
            <button
              className={style.btn_5}
              onClick={() => {
                setSlideState({ ...slideState, 5: true });
                setGoNextActive({ ...goNextActive, 5: true });
              }}
            >
              <div className={style.btn_5_img} />
              <div className={style.cross4} />
            </button>
          </>
        )}
        {slideState[4] ? (
          <>
            <div className={style.fade_deep3}>
              <button
                className={style.btn_close}
                onClick={() => setSlideState({ ...slideState, 4: false })}
              />
            </div>
            <div className={cn(style.bt4_txt, "fadeInDown dur800")} />
          </>
        ) : (
          <>
            <button
              className={style.btn_4}
              onClick={() => {
                setSlideState({ ...slideState, 4: true });
                setGoNextActive({ ...goNextActive, 4: true });
              }}
            >
              <div className={style.btn_4_img} />
              <div className={style.cross3} />
            </button>
          </>
        )}
        {slideState[3] ? (
          <>
            <div className={style.fade_deep2}>
              <button
                className={style.btn_close}
                onClick={() => setSlideState({ ...slideState, 3: false })}
              />
            </div>
            <div className={cn(style.bt3_txt, "fadeInDown dur800")} />
          </>
        ) : (
          <>
            <button
              className={style.btn_3}
              onClick={() => {
                setSlideState({ ...slideState, 3: true });
                setGoNextActive({ ...goNextActive, 3: true });
              }}
            >
              <div className={style.btn_3_img} />
              <div className={style.cross2} />
            </button>
          </>
        )}
        {slideState[2] ? (
          <>
            <div className={style.fade_deep1}>
              <button
                className={style.btn_close}
                onClick={() => setSlideState({ ...slideState, 2: false })}
              />
            </div>
            <div className={cn(style.bt2_txt, "fadeInDown dur800")} />
          </>
        ) : (
          <>
            <button
              className={style.btn_2}
              onClick={() => {
                setSlideState({ ...slideState, 2: true });
                setGoNextActive({ ...goNextActive, 2: true });
              }}
            >
              <div className={style.btn_2_img} />
              <div className={style.cross1} />
            </button>
          </>
        )}
        {slideState[1] ? (
          <>
            <div className={style.fade_deep}>
              <button
                className={style.btn_close}
                onClick={() => setSlideState({ ...slideState, 1: false })}
              />
            </div>
            <div className={cn(style.bt1_txt, "fadeInDown dur800")} />
          </>
        ) : (
          <>
            <button
              className={style.btn_1}
              onClick={() => {
                setSlideState({ ...slideState, 1: true });
                setGoNextActive({ ...goNextActive, 1: true });
              }}
            >
              <div className={style.btn_1_img} />
              <div className={style.cross} />
            </button>
          </>
        )}
        <div className={style.help_info} />
        <div className={style.title} />
        <div className={style.timeline} />
        <div className={style.note} />
        <div className={style.btn_go_off} data-store="3" />
        {goNextActive[1] &&
          goNextActive[2] &&
          goNextActive[3] &&
          goNextActive[4] &&
          goNextActive[5] &&
          goNextActive[6] &&
          goNextActive[7] && (
            <button className={style.btn_go_on} onClick={goNextSlide} />
          )}
        <button className={style.btn_back_on} onClick={goPrevSlide} />
      </div>
    </div>
  );
};
export default Slide_10;
