import style from "./neo_modul_008_2409.module.scss";
import { useState } from "react";
import cn from "classnames";

const Slide_8 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slideState, setSlideState] = useState(0);
  const [slidePop, setSlidePop] = useState(0);
  const [slidePopState, setSlidePopState] = useState({
    1: false,
    2: false,
  });
  const [goNextActive, setGoNextActive] = useState(false);

  return (
    <div className={cn(style.neo_modul_008_2409)}>
      <div className={style.scaleWrapper}>
        <div className={style.timeline} />
        <div className={style.note} />
        <div className={style.btn_go_off} data-state="0" />
        {goNextActive && slidePopState[1] && slidePopState[2] && (
          <button className={style.btn_go_on} onClick={goNextSlide} />
        )}
        <button className={style.btn_back_on} onClick={goPrevSlide} />
        <div className={style.condBlock}>
          {slideState === 0 && (
            <>
              {" "}
              <div className={style.condWrapper}></div>
              <button
                className={style.ellipse}
                onClick={() => setSlideState(1)}
              />
              <div className={style.title} />
            </>
          )}

          {slideState === 1 && (
            <div className={style.condWrapper}>
              <div className={style.logo_black} />
              <div className={cn(style.message, "fadeInDown dur800 del400")} />
              <button
                className={style.btn_pop_1}
                onClick={() => {
                  setSlidePop(1);
                  setGoNextActive(true);
                }}
              />
            </div>
          )}
        </div>

        <div className={style.popupBlock}>
          {slidePop === 1 && (
            <div className={style.popupWrapper}>
              <div className={style.rectangle} />
              <div className={style.img_skin} />
              {slidePopState[2] ? (
                <div
                  className={cn(style.block_2, "fadeInDown dur900 del400")}
                />
              ) : (
                <button
                  className={style.btn_2}
                  onClick={() =>
                    setSlidePopState({ ...slidePopState, 2: true })
                  }
                />
              )}

              <div className={style.pop_txt2} />
              {slidePopState[1] ? (
                <div
                  className={cn(style.block_1, "fadeInDown dur900 del400")}
                />
              ) : (
                <button
                  className={style.btn_1}
                  onClick={() =>
                    setSlidePopState({ ...slidePopState, 1: true })
                  }
                />
              )}
              <div className={style.pop_txt1} />
              <div className={style.pop_ttl} />
              <button
                className={style.btn_popup_close_1}
                onClick={() => setSlidePop(0)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Slide_8;
