import { useState, useEffect } from "react";
import cn from "classnames";

import style from "./neo_modul_028_2409.module.scss";

const Slide_28 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slidePop, setSlidePop] = useState(0);

  const [userAnswer, setUserAnswer] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });

  const getPopState = () => {
    if (
      userAnswer[1] &&
      !userAnswer[2] &&
      userAnswer[3] &&
      userAnswer[4] &&
      !userAnswer[5]
    ) {
      return 2;
    } else if (
      (userAnswer[1] || userAnswer[3] || userAnswer[4]) &&
      !userAnswer[2] &&
      !userAnswer[5]
    ) {
      return 3;
    } else {
      return 1;
    }
  };
  useEffect(() => {
    if (sessionStorage.getItem("neo_modul_028_2409")) {
      setSlidePop(2);
      setUserAnswer(JSON.parse(sessionStorage.getItem("neo_modul_028_2409")));
    }
  }, []);

  return (
    <div className={cn(style.neo_modul_028_2409)}>
      <div className="scale-wrapper">
        <button
          className={cn(
            userAnswer[5] ? style.btn_5_on : style.btn_5_off,
            slidePop ? style.btn_5_wrong : ""
          )}
          onClick={() => setUserAnswer({ ...userAnswer, 5: !userAnswer[5] })}
        />
        <button
          className={cn(
            userAnswer[4] ? style.btn_4_on : style.btn_4_off,
            slidePop ? style.btn_4_right : ""
          )}
          onClick={() => setUserAnswer({ ...userAnswer, 4: !userAnswer[4] })}
        />
        <button
          className={cn(
            userAnswer[3] ? style.btn_3_on : style.btn_3_off,
            slidePop ? style.btn_3_right : ""
          )}
          onClick={() => setUserAnswer({ ...userAnswer, 3: !userAnswer[3] })}
        />
        <button
          className={cn(
            userAnswer[2] ? style.btn_2_on : style.btn_2_off,
            slidePop ? style.btn_2_wrong : ""
          )}
          onClick={() => setUserAnswer({ ...userAnswer, 2: !userAnswer[2] })}
        />
        <button
          className={cn(
            userAnswer[1] ? style.btn_1_on : style.btn_1_off,
            slidePop ? style.btn_1_right : ""
          )}
          onClick={() => setUserAnswer({ ...userAnswer, 1: !userAnswer[1] })}
        />
        <div className={style.btn_off} data-state="0" />
        {(userAnswer[1] ||
          userAnswer[2] ||
          userAnswer[3] ||
          userAnswer[4] ||
          userAnswer[5]) && (
          <button
            className={style.btn_on}
            onClick={() => {
              setSlidePop(() => getPopState());
              if (
                userAnswer[1] &&
                !userAnswer[2] &&
                userAnswer[3] &&
                userAnswer[4] &&
                !userAnswer[5]
              ) {
                sessionStorage.setItem(
                  "neo_modul_028_2409",
                  JSON.stringify(userAnswer)
                );
              }
            }}
          />
        )}
        <div className={cn(style.help_info, "jackInTheBox dur1000 del600")} />
        <div className={style.title} />
        <div className={style.quest} />
        <div className={style.timeline} />
        <div className={style.note} />
        <div className={style.btn_go_off} />
        {slidePop == 2 && (
          <button
            className={style.btn_go_on}
            onClick={() => {
              goNextSlide();
            }}
          />
        )}

        <button
          className={style.btn_back_on}
          onClick={() => {
            goPrevSlide();
          }}
        />

        <div className="popup-block">
          {slidePop == 1 && (
            <div className={style.popupWrapper}>
              <div className={style.pop_base_w} />
              <button
                className={style.btn_again_w}
                onClick={() => {
                  setSlidePop(0);
                  setUserAnswer({
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                  });
                }}
              />
              <div className={style.pop_txt_w} />
              <button
                className={style.btn_close_w}
                onClick={() => {
                  setSlidePop(0);
                  setUserAnswer({
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                  });
                }}
              />
            </div>
          )}

          {slidePop == 3 && (
            <div className={style.popupWrapper}>
              <div className={style.pop_back_right} />
              <button
                className={style.btn_again_r}
                onClick={() => {
                  setSlidePop(0);
                  setUserAnswer({
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                  });
                }}
              />
              <div className={style.pop_txt_r} />
              <button
                className={style.btn_close_r}
                data-state="0"
                onClick={() => {
                  setSlidePop(0);
                  setUserAnswer({
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Slide_28;
