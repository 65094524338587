import { useState, useEffect } from "react";
import style from "./neo_modul_024_2409.module.scss";
import cn from "classnames";

const Slide_24 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slidePop, setSlidePop] = useState(0);

  const [userAnswer, setUserAnswer] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });

  useEffect(() => {
    if (sessionStorage.getItem("neo_modul_024_2409")) {
      setSlidePop(2);
      setUserAnswer(JSON.parse(sessionStorage.getItem("neo_modul_024_2409")));
    }
  }, []);

  return (
    <div className={cn(style.neo_modul_024_2409)}>
      <div className="scale-wrapper">
        <button
          className={cn(
            userAnswer[4] ? style.btn_4_on : style.btn_4_off,
            slidePop ? style.btn_4_right : ""
          )}
          onClick={() => setUserAnswer({ ...userAnswer, 4: !userAnswer[4] })}
        />
        <button
          className={cn(
            userAnswer[3] ? style.btn_3_on : style.btn_3_off,
            slidePop ? style.btn_3_right : ""
          )}
          onClick={() => setUserAnswer({ ...userAnswer, 3: !userAnswer[3] })}
        />
        <button
          className={cn(
            userAnswer[2] ? style.btn_2_on : style.btn_2_off,
            slidePop ? style.btn_2_right : ""
          )}
          onClick={() => setUserAnswer({ ...userAnswer, 2: !userAnswer[2] })}
        />
        <button
          className={cn(
            userAnswer[1] ? style.btn_1_on : style.btn_1_off,
            slidePop ? style.btn_1_right : ""
          )}
          onClick={() => setUserAnswer({ ...userAnswer, 1: !userAnswer[1] })}
        />
        <div className={style.btn_off} data-state="0" />
        {(userAnswer[1] || userAnswer[2] || userAnswer[3] || userAnswer[4]) && (
          <button
            className={style.btn_on}
            onClick={() => {
              setSlidePop(
                userAnswer[1] && userAnswer[2] && userAnswer[3] && userAnswer[4]
                  ? 2
                  : 1
              );
              if (
                userAnswer[1] &&
                userAnswer[2] &&
                userAnswer[3] &&
                userAnswer[4]
              ) {
                sessionStorage.setItem(
                  "neo_modul_024_2409",
                  JSON.stringify(userAnswer)
                );
              }
            }}
          />
        )}

        <div className={cn(style.help_info, "jackInTheBox dur1000 del600")} />
        <div className={style.title} />
        <div className={style.guest} />
        <div className={style.timeline} />
        <div className={style.note} />
        <div className={style.btn_go_off} data-state="0" />
        {slidePop == 2 && (
          <button
            className={style.btn_go_on}
            onClick={() => {
              goNextSlide();
            }}
          />
        )}

        <button
          className={style.btn_back_on}
          onClick={() => {
            goPrevSlide();
          }}
        />

        <div className="popup-block">
          {slidePop == 1 && (
            <div className={style.popupWrapper}>
              <div className={style.rectangle} />
              <button
                className={style.btn_pop_again}
                onClick={() => {
                  setSlidePop(0);
                  setUserAnswer({
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                  });
                }}
              />
              <div className={style.pop_txt} />
              <button
                className={style.btn_popup_close_1}
                onClick={() => {
                  setSlidePop(0);
                  setUserAnswer({
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Slide_24;
