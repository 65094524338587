import { useState, useEffect } from "react";
import style from "./neo_modul_023_2409.module.scss";
import cn from "classnames";

const Slide_23 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [userAnswer, setUserAnswer] = useState(0);
  const [slidePop, setSlidePop] = useState(0);
  useEffect(() => {
    if (sessionStorage.getItem("neo_modul_023_2409")) {
      setSlidePop(2);
      setUserAnswer(+JSON.parse(sessionStorage.getItem("neo_modul_023_2409")));
    }
  }, []);

  return (
    <div className={cn(style.neo_modul_023_2409)}>
      <div className="scale-wrapper">
        <button
          className={cn(
            userAnswer == 4 ? style.btn_4_on : style.btn_4_off,
            slidePop == 1 ? style.btn_4_wrong : ""
          )}
          onClick={() => setUserAnswer(4)}
        />
        <button
          className={cn(
            userAnswer == 3 ? style.btn_3_on : style.btn_3_off,
            slidePop == 2 ? style.btn_3_right : ""
          )}
          onClick={() => {
            setUserAnswer(3);
          }}
        />
        <button
          className={cn(
            userAnswer == 2 ? style.btn_2_on : style.btn_2_off,
            slidePop == 1 ? style.btn_2_wrong : ""
          )}
          onClick={() => setUserAnswer(2)}
        />
        <button
          className={cn(
            userAnswer == 1 ? style.btn_1_on : style.btn_1_off,
            slidePop == 1 ? style.btn_1_wrong : ""
          )}
          onClick={() => setUserAnswer(1)}
        />
        <div className={style.btn_off} data-state="0" />
        {userAnswer && (
          <button
            className={style.btn_on}
            onClick={() => {
              setSlidePop(userAnswer != 3 ? 1 : 2);
              if (userAnswer == 3) {
                sessionStorage.setItem("neo_modul_023_2409", 3);
              }
            }}
          />
        )}
        <div className={cn(style.help_info, "jackInTheBox dur1000 del600")} />
        <div className={style.title} />
        <div className={style.quest} />
        <div className={style.timeline} />
        <div className={style.note} />
        <div className={style.btn_go_off} data-state="0" />
        {userAnswer == 3 && slidePop == 2 && (
          <button
            className={style.btn_go_on}
            onClick={() => {
              goNextSlide();
            }}
          />
        )}
        <button
          className={style.btn_back_on}
          onClick={() => {
            goPrevSlide();
          }}
        />

        <div className="popup-block">
          {slidePop == 1 && userAnswer != 3 && (
            <>
              {" "}
              <div className={style.popupWrapper}>
                <div className={style.pop_back} />
                <button
                  className={style.pop_btn_again}
                  onClick={() => {
                    setSlidePop(0);
                    setUserAnswer(0);
                  }}
                />
                <div className={style.pop_txt} />
                <button
                  className={style.btn_popup_close_1}
                  onClick={() => {
                    setSlidePop(0);
                    setUserAnswer(0);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Slide_23;
