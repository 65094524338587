import style from "./neo_modul_005_2409.module.scss";
import { useState } from "react";
import cn from "classnames";
const Slide_5 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slideState, setSlideState] = useState({
    1: false,
    2: false,
    3: false,
  });

  return (
    <div className={cn(style.neo_modul_005_2409)}>
      <div className={style.scaleWrapper}>
        <div className={cn(style.line, "growX dur800 del800")} />
        <button
          className={cn(style.block_3, "fadeInRight dur800 del1000")}
          onClick={() => setSlideState({ ...slideState, 3: true })}
        />
        <button
          className={cn(style.block_2, "fadeInRight dur800 del800")}
          onClick={() => setSlideState({ ...slideState, 2: true })}
        />
        <button
          className={cn(style.block_1, "fadeInRight dur800 del600")}
          onClick={() => setSlideState({ ...slideState, 1: true })}
        />
        <div className={style.line_txt_dowwn} />
        <div className={style.line_txt_up} />
        <div className={style.timeline} />
        <div className={style.note} />
        <div className={style.btn_go_off} />
        <button className={style.btn_back_on} onClick={goPrevSlide} />
        <div className={style.rectangle} />
        <div className={style.shape} />
        <div className="cond-block">
          {slideState[1] && (
            <>
              <div className="cond-wrapper"></div>
              <div className={style.bt1_open} />
              <div className={style.bt1_op_txt} />
            </>
          )}
          {slideState[2] && (
            <>
              <div className="cond-wrapper"></div>
              <div className={style.bt2_open} />
              <div className={style.bt2_op_txt} />
            </>
          )}
          {slideState[3] && (
            <>
              <div className="cond-wrapper"></div>
              <div className={style.bt3_open} />
              <div className={style.bt3_op_txt} />
            </>
          )}
          {slideState[1] && slideState[2] && slideState[3] && (
            <button className={style.btn_go_on} onClick={goNextSlide} />
          )}
        </div>
      </div>
    </div>
  );
};
export default Slide_5;
