import { useState } from "react";
import style from "./neo_modul_022_2409.module.scss";
import cn from "classnames";

const Slide_22 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slideState, setSlideState] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });
  return (
    <div className={cn(style.neo_modul_022_2409)}>
      <div className="scale-wrapper">
        <div className={style.pack} />
        {slideState[5] ? (
          <div className={cn(style.ing_5, "fadeIn dur1000")} />
        ) : (
          <button
            className={style.btn_5}
            onClick={() => setSlideState({ ...slideState, 5: true })}
          />
        )}
        {slideState[4] ? (
          <div className={cn(style.ing_4, "fadeIn dur1000")} />
        ) : (
          <button
            className={style.btn_4}
            onClick={() => setSlideState({ ...slideState, 4: true })}
          />
        )}
        {slideState[3] ? (
          <div className={cn(style.ing_3, "fadeIn dur1000")} />
        ) : (
          <button
            className={style.btn_3}
            onClick={() => setSlideState({ ...slideState, 3: true })}
          />
        )}
        {slideState[2] ? (
          <>
            <div className={cn(style.ing_2, "fadeIn dur1000")} />
          </>
        ) : (
          <button
            className={style.btn_2}
            onClick={() => setSlideState({ ...slideState, 2: true })}
          />
        )}
        {slideState[1] ? (
          <div className={cn(style.ing_1, "fadeIn dur1000")} />
        ) : (
          <button
            className={style.btn_1}
            onClick={() => setSlideState({ ...slideState, 1: true })}
          />
        )}
        <div className={style.title} />
        <div className={style.timeline} />
        <div className={style.note} />
        <div className={style.btn_go_off} data-store="3" />
        {slideState[1] &&
          slideState[2] &&
          slideState[3] &&
          slideState[4] &&
          slideState[5] && (
            <button className={style.btn_go_on} onClick={goNextSlide} />
          )}
        <button className={style.btn_back_on} onClick={goPrevSlide} />
      </div>
    </div>
  );
};
export default Slide_22;
