// import React from "react";
// import Slide from "../../Components/Slide/Slide.js";
import style from "./neo_modul_001_2409.module.scss";
import cn from "classnames";
import "../../../src/assets/styles/Animate.scss";

const Slide_1 = ({ goTo }) => {
  const { goNextSlide } = goTo;
  return (
    <div className={cn(style.neo_modul_001_2409)}>
      <div className={style.scaleWrapper}>
        <div className={cn(style.title, "fadeInDown dur1000 del600")} />
        <div className={style.logovichyblanc} />
        <div className={style.timeline} />
        <div className={style.btn_back_off} />
        <button className={style.btn_go_on} onClick={goNextSlide} />
      </div>
    </div>
  );
};

export default Slide_1;
