import style from "./neo_modul_013_2409.module.scss";
import { useState } from "react";
import cn from "classnames";

const Slide_13 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slideState, setSlideState] = useState(0);
  return (
    <div className={cn(style.neo_modul_013_2409)}>
      <div className={style.scaleWrapper}>
        <div className={style.cross} />
        <div className={style.help_info} />
        <div className={cn(style.txt_1, "fadeInDown dur1000 del1000")} />
        <div className={cn(style.title, "fadeInDown dur1000 del600")} />
        <div className={style.timeline} />
        <div className={style.note} />
        <div className={style.btn_go_off} data-state="0" />
        <button className={style.btn_back_on} onClick={goPrevSlide} />
        <div className={style.img2} />
        <div className={style.img_1} />
        <div className={style.condBlock}>
          {slideState === 1 && (
            <>
              <div className={cn(style.txt_2, "fadeInDown dur1000 del600")} />
              <div className={style.name_2} />
              <div className={style.name_1} />
              <div className={style.cross} />
            </>
          )}

          {slideState === 0 && (
            <>
              {" "}
              <div className={style.condWrapper}></div>
              <div className={style.fade} />
              <div className={style.ing_2_txt} />
              <div className={style.ing_1_txt} />
              <div className={style.help_info} />
              <button
                className={style.cross}
                onClick={() => setSlideState(1)}
              />
            </>
          )}
        </div>
      </div>
      {slideState && (
        <button className={style.btn_go_on} onClick={goNextSlide} />
      )}
    </div>
  );
};
export default Slide_13;
