import { useState } from "react";
import cn from "classnames";

import style from "./neo_modul_031_2409.module.scss";

const Slide_31 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slideState, setSlideState] = useState(0);

  return (
    <div className={cn(style.neo_modul_031_2409)}>
      <div className="scale-wrapper">
        <div className={cn(style.title, "fadeInDown dur1000 del600")} />
        <div className={style.timeline} />
        <div className={style.btn_go_off} />
        <button
          className={style.btn_back_on}
          onClick={() => {
            goPrevSlide();
          }}
        />
      </div>
    </div>
  );
};

export default Slide_31;
