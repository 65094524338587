import { useState } from "react";
// import Slide from '../../Components/Slide/Slide.js';
import style from "./neo_modul_017_2409.module.scss";
import cn from "classnames";

const Slide_17 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slideState, setSlideState] = useState(0);
  const [cardState, setCardState] = useState({
    1: false,
    2: false,
  });
  return (
    <div className={cn(style.neo_modul_017_2409)}>
      <div className={style.scaleWrapper}>
        <div className={style.btn1} />
        <div className={cn(style.title, "fadeInDown dur1000 del600")} />
        <div className={style.timeline} />

        <div className={style.btn_go_off} />
        {cardState[2] && cardState[1] && (
          <button className={style.btn_go_on} onClick={goNextSlide} />
        )}
        <button className={style.btn_back_on} onClick={goPrevSlide} />
        {/* <div className={style.cond-block} /> */}
        {slideState == 1 && (
          <>
            <div className={cn(style.gr_3, "fadeIn dur1000 del600")} />
            {cardState[1] ? (
              <div className={style.message2} />
            ) : (
              <button
                className={style.btn}
                // onClick={(e) => (e.target.style.display = "none")}
                onClick={() => setCardState({ ...cardState, 1: true })}
              />
            )}

            <div className={style.btn_2_on} />
            <button
              className={style.btn_1_off}
              onClick={() => setSlideState(0)}
            />
          </>
        )}

        {slideState == 0 && (
          <>
            {cardState[2] ? (
              <div className={style.message1} />
            ) : (
              <button
                className={style.btn}
                onClick={() => setCardState({ ...cardState, 2: true })}
              />
            )}

            <div className={cn(style.graph_2, "fadeIn dur1000 del600")} />
            <div className={cn(style.graph_1, "fadeIn dur1000 del600")} />
            <button
              className={style.btn_2_off}
              onClick={() => setSlideState(1)}
            />
            <div className={style.btn_1_on} />
          </>
        )}
        <div className={style.note} />
      </div>
    </div>
  );
};

export default Slide_17;
