import style from "./neo_modul_016_2409.module.scss";
import { useState } from "react";
import cn from "classnames";

const Slide_16 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slidePop, setSlidePop] = useState(0);
  const [slideState, setSlideState] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });
  const [goNextActive, setGoNextActive] = useState(false);

  return (
    <div className={cn(style.neo_modul_016_2409)}>
      <div className={style.scaleWrapper}>
        {slideState[4] ? (
          <>
            <div className={cn(style.block_4, "fadeIn dur1000 ")} />
            <div className={cn(style.message, "fadeInDown dur1000 del600")} />
            <button
              className={cn(style.btn_info, "fadeIn dur1000 del400")}
              onClick={() => {
                setSlidePop(1);
                setGoNextActive(true);
              }}
            />
          </>
        ) : (
          <button
            className={cn(style.btn_4, "fadeInDown dur600 del1000")}
            onClick={() => setSlideState({ ...slideState, 4: true })}
          />
        )}

        {slideState[3] ? (
          <div className={cn(style.block_3, "fadeIn dur1000 ")} />
        ) : (
          <button
            className={cn(style.btn_3, "fadeInDown dur600 del800")}
            onClick={() => setSlideState({ ...slideState, 3: true })}
          />
        )}

        {slideState[2] ? (
          <div className={cn(style.block_2, "fadeIn dur1000 ")} />
        ) : (
          <button
            className={cn(style.btn_2, "fadeInDown dur600 del600")}
            onClick={() => setSlideState({ ...slideState, 2: true })}
          />
        )}

        {slideState[1] ? (
          <div className={cn(style.block_1, "fadeIn dur1000 ")} />
        ) : (
          <button
            className={cn(style.btn_1, "fadeInDown dur600 del400")}
            onClick={() => setSlideState({ ...slideState, 1: true })}
          />
        )}

        {!slideState[4] && <div className={style.help_info} />}
        <div className={style.title} />
        <div className={style.timeline} />
        <div className={style.note} />
        <div className={style.btn_go_off} data-state="0" />
        {goNextActive && (
          <button className={style.btn_go_on} onClick={goNextSlide} />
        )}
        <button className={style.btn_back_on} onClick={goPrevSlide} />

        <div className={style.popupBlock}>
          {slidePop === 1 && (
            <div className={style.popupWrapper}>
              <div className={style.popup_base} />
              <div className={style.pop_txt} />
              <button
                className={style.btn_popup_close_1}
                onClick={() => setSlidePop(0)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Slide_16;
