import style from "./neo_modul_011_2409.module.scss";
import { useState } from "react";
import cn from "classnames";

const Slide_11 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slideState, setSlideState] = useState({
    1: false,
    2: false,
  });
  return (
    <div className={cn(style.neo_modul_011_2409)}>
      <div className={style.scaleWrapper}>
        {slideState[2] ? (
          <>
            <div className={cn(style.bt2_back, "fadeIn dur800")} />
            <div className={cn(style.bl2_txt, "fadeInDown dur800 del400")} />
            <div className={cn(style.line, "fadeInDown dur800 del400")} />
            <div className={cn(style.num_02, "fadeInDown dur800 del400")} />
          </>
        ) : (
          <>
            <div className={style.help_info2} />
            <button
              className={style.btn_2}
              onClick={() => setSlideState({ ...slideState, 2: true })}
            />
          </>
        )}
        {slideState[1] ? (
          <>
            <div className={cn(style.bt1_back, "fadeIn dur800")} />
            <div className={cn(style.bl1_txt, "fadeInDown dur800 del400")} />
            <div className={cn(style.line_1, "fadeInDown dur800 del400")} />
            <div className={cn(style.num_01, "fadeInDown dur800 del400")} />
          </>
        ) : (
          <>
            <div className={style.help_info1} />
            <button
              className={style.btn_1}
              onClick={() => setSlideState({ ...slideState, 1: true })}
            />
          </>
        )}
        <div className={style.timeline} />
        <div className={style.note} />
        <div className={style.btn_go_off} data-store="3" />
        <button className={style.btn_back_on} onClick={goPrevSlide} />
        {slideState[1] && slideState[2] && (
          <button className={style.btn_go_on} onClick={goNextSlide} />
        )}
      </div>
    </div>
  );
};
export default Slide_11;
