import { useState } from "react";
// import Slide from '../../Components/Slide/Slide.js';
import style from "./neo_modul_006_2409.module.scss";
import cn from "classnames";

const Slide_6 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slidePop, setSlidePop] = useState(0);
  const [slideState, setSlideState] = useState({
    0: true,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });

  return (
    <div className={cn(style.neo_modul_006_2409)}>
      <div className={style.scaleWrapper}>
        <div className={style.title} />
        <div className={style.timeline} />
        <div className={style.note} />
        <div className={style.btn_go_off} />
        {/* <div className={style.btn_go_on} data-state="0" /> */}
        <button className={style.btn_back_on} onClick={goPrevSlide} />
        {slideState[4] ? (
          <>
            <div className={cn(style.block_4_back_on, "fadeIn del dur800")} />
            <div className={cn(style.block_4_on_txt2, "fadeIn del dur800")} />
            <div className={cn(style.block_4_on_txt1, "fadeIn del dur800")} />
            <button
              className={cn(style.btn_pop_1, "fadeIn del dur800")}
              onClick={() => {
                setSlidePop(1);
                setSlideState({ ...slideState, 5: true });
              }}
            />
          </>
        ) : (
          <>
            <button
              className={style.block_4_back_off}
              onClick={() => setSlideState({ ...slideState, 4: true })}
            />
            <button
              className={style.block_q_4}
              onClick={() => setSlideState({ ...slideState, 4: true })}
            />
            <button
              className={style.help_info_4}
              onClick={() => setSlideState({ ...slideState, 4: true })}
            />
          </>
        )}
        {slideState[3] ? (
          <>
            <div className={cn(style.block_3_back_on, "fadeIn del dur800")} />
            <div className={cn(style.block_3_on_txt2, "fadeIn del dur800")} />
            <div className={cn(style.block_3_on_txt1, "fadeIn del dur800")} />
          </>
        ) : (
          <>
            <button
              className={style.block_3_back_off}
              onClick={() => setSlideState({ ...slideState, 3: true })}
            />
            <button
              className={style.block_q_3}
              onClick={() => setSlideState({ ...slideState, 3: true })}
            />
            <button
              className={style.help_info_3}
              onClick={() => setSlideState({ ...slideState, 3: true })}
            />
          </>
        )}
        {slideState[2] ? (
          <>
            <div className={cn(style.block_2_back_on, "fadeIn del dur800")} />
            <div className={cn(style.block_2_on_txt2, "fadeIn del dur800")} />
            <div className={cn(style.block_2_on_txt1, "fadeIn del dur800")} />
          </>
        ) : (
          <>
            <button
              className={style.block_2_back_off}
              onClick={() => setSlideState({ ...slideState, 2: true })}
            />
            <button
              className={style.block_q_2}
              onClick={() => setSlideState({ ...slideState, 2: true })}
            />
            <button
              className={style.help_info_2}
              onClick={() => setSlideState({ ...slideState, 2: true })}
            />
          </>
        )}
        {slideState[1] ? (
          <>
            <div className={cn(style.block_1_back_on, "fadeIn del dur800")} />
            <div className={cn(style.block_1_on_txt2, "fadeIn del dur800")} />
            <div className={cn(style.block_1_on_txt1, "fadeIn del dur800")} />
          </>
        ) : (
          <>
            <button
              className={style.block_1_back_off}
              onClick={() => setSlideState({ ...slideState, 1: true })}
            />
            <button
              className={style.block_q_1}
              onClick={() => setSlideState({ ...slideState, 1: true })}
            />
            <button
              className={style.help_info_1}
              onClick={() => setSlideState({ ...slideState, 1: true })}
            />
          </>
        )}
        {slideState[1] &&
          slideState[2] &&
          slideState[3] &&
          slideState[4] &&
          slideState[5] && (
            <button className={style.btn_go_on} onClick={goNextSlide} />
          )}

        <div className="popup-block">
          {slidePop == 1 && (
            <>
              <div className={style.popupWrapper}>
                <div className={style.popup_base} />
                <div className={style.table_pop} />
                <div className={style.txt_pop} />
                <button
                  className={style.btn_popup_close_1}
                  onClick={() => setSlidePop(0)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Slide_6;
