// import React from 'react';
// import Slide from '../../Components/Slide/Slide.js';
import style from "./neo_modul_002_2409.module.scss";
import cn from "classnames";

const Slide_2 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;

  return (
    <div className={style.neo_modul_002_2409}>
      <div className={style.scaleWrapper}>
        <div className={cn(style.block_3_txt, "fadeInLeft dur1000 del1000")} />
        <div className={style.txt_03} />
        <div className={cn(style.block_2_txt, "fadeInLeft dur1000 del800")} />
        <div className={style.txt_02} />
        <div className={cn(style.block_1_txt, "fadeInLeft dur1000 del600")} />
        <div className={style.txt_01} />
        <div className={cn(style.title, "fadeInDown dur1000 del600")} />
        <div className={cn(style.arrow3, "fadeInDown dur1000 del1000")} />
        <div className={cn(style.help_info_3, "fadeIn dur1000 del1200")} />
        <div className={cn(style.arrow2, "fadeInDown dur1000 del800")} />
        <div className={cn(style.help_info_2, "fadeIn dur1000 del1000")} />
        <div className={cn(style.arrow1, "fadeInRight dur1000 del600")} />
        <div className={cn(style.help_info_1, "fadeIn dur1000 del800")} />

        <div className={style.timeline} />

        <button className={style.btn_back_on} onClick={goPrevSlide} />
        <div className={style.btn_go_off} />
        <button className={style.btn_go} onClick={goNextSlide} />
        {/* <div className={style.timeline} />
        <div className={style.btn_go_off} />
        <div className={style.btn_back_on} />
        <div className={style.btn_go} /> */}
      </div>
    </div>
  );
};
export default Slide_2;
