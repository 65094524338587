import style from "./neo_modul_012_2409.module.scss";
import { useState } from "react";
import cn from "classnames";

const Slide_12 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slideState, setSlideState] = useState(0);
  const [goNextActive, setGoNextActive] = useState(false);

  return (
    <div className={cn(style.neo_modul_012_2409)}>
      <div className={style.scaleWrapper}>
        <div className={cn(style.subtitle_4, "fadeInRight dur900 del1000")} />
        <div className={cn(style.subtitle_3, "fadeInRight dur900 del800")} />
        <div className={cn(style.subtitle_2, "fadeInRight dur900 del600")} />
        <div className={cn(style.subtitle_1, "fadeInRight dur900 del400")} />
        <div className={cn(style.line, "growX dur900 del400")} />
        <div className={cn(style.block_4, "fadeInDown dur900 del1200")} />
        <div className={cn(style.block_2, "fadeInDown dur900 del800")} />
        <div className={cn(style.block_1, "fadeInDown dur900 del600")} />
        <div className={cn(style.pack5, "fadeInDown dur900 del1200")} />
        <div className={cn(style.pack4, "fadeInDown dur900 del1200")} />
        <div className={cn(style.pack3, "fadeInDown dur900 del800")} />
        <div className={cn(style.pack2, "fadeInDown dur900 del600")} />
        <div className={cn(style.pack1, "fadeInDown dur900 del600")} />
        <div className={cn(style.icon_2, "fadeInRight dur900 del400")} />
        <div className={cn(style.icon_1, "fadeInRight dur900 del400")} />
        <div className={cn(style.even, "fadeInRight dur900 del400")} />
        <div className={cn(style.morn, "fadeInRight dur900 del400")} />
        <div className={cn(style.title, "fadeInDown dur600 del400")} />
        <div className={cn(style.timeline, "fadeInRight dur900 del400")} />
        <div className={cn(style.note, "fadeInRight dur900 del400")} />
        <button className={style.btn_go_off} />
        {goNextActive && (
          <button className={style.btn_go_on} onClick={goNextSlide} />
        )}
        <button className={style.btn_back_on} onClick={goPrevSlide} />
        <div className={style.condBlock}>
          {slideState === 0 && (
            <>
              {" "}
              <div className={style.condWrapper}></div>
              <div
                className={cn(style.block_3_pred, "fadeInDown dur900 del1000")}
              />
              <button
                className={style.btn_2_off}
                onClick={() => {
                  setSlideState(1);
                  setGoNextActive(true);
                }}
              />
              <div className={style.btn_1_on} />
            </>
          )}

          {slideState === 1 && (
            <>
              {" "}
              <div className={style.condWrapper}></div>
              <div
                className={cn(style.block_3_post, "fadeInDown dur900 del1000")}
              />
              <div className={style.btn_2_on} />
              <button
                className={style.btn_1_off}
                onClick={() => {
                  setSlideState(0);
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Slide_12;
