import { useState } from "react";
// import React from 'react';
// import Slide from '../../Components/Slide/Slide.js';
import style from "./neo_modul_019_2409.module.scss";
import cn from "classnames";

const Slide_19 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slideState, setSlideState] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });
  const [popState, setPopState] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });
  const [slidePop, setSlidePop] = useState(0);
  return (
    <div className={cn(style.neo_modul_019_2409)}>
      <div className={style.scaleWrapper}>
        {slideState[4] ? (
          <div className={cn(style.ing_4, "fadeIn dur1000 ")} />
        ) : (
          <button
            className={style.btn_41}
            onClick={() => setSlideState({ ...slideState, 4: true })}
          />
        )}
        {slideState[3] ? (
          <div className={cn(style.ing_3, "fadeIn dur1000 ")} />
        ) : (
          <button
            className={style.btn_31}
            onClick={() => setSlideState({ ...slideState, 3: true })}
          />
        )}
        {slideState[2] ? (
          <>
            <div className={cn(style.ing_2, "fadeIn dur1000 ")} />
            <button
              className={style.btn_pop_1}
              onClick={() => {
                setSlidePop(1);
                setPopState({ ...popState, 5: true });
              }}
            />
          </>
        ) : (
          <button
            className={style.btn_21}
            onClick={() => setSlideState({ ...slideState, 2: true })}
          />
        )}
        {slideState[1] ? (
          <div className={cn(style.ing_1, "fadeIn dur1000 ")} />
        ) : (
          <button
            className={style.btn_11}
            onClick={() => setSlideState({ ...slideState, 1: true })}
          />
        )}
        <div className={style.pack2} />
        <div className={style.pack1} />
        <button
          className={style.btn_pop_2}
          onClick={() => {
            setSlidePop(2);
          }}
          state={{ popState: popState }}
        />

        <div className={style.ttl_rect} />
        <div className={style.title} />
        <div className={style.timeline} />
        <div className={style.note} />
        <div className={style.btn_go_off} />
        {slideState[1] &&
          slideState[2] &&
          slideState[3] &&
          slideState[4] &&
          popState[1] &&
          popState[2] &&
          popState[3] &&
          popState[4] &&
          popState[5] && (
            <button className={style.btn_go_on} onClick={goNextSlide} />
          )}
        <button className={style.btn_back_on} onClick={goPrevSlide} />

        {/* <div className="popup-block"> */}
        {slidePop == 2 && (
          <div className={style.popupWrapper}>
            <div className={style.pop_back} />
            <div className={style.pop_graph} />
            {popState[4] ? (
              <div className={style.block_4} />
            ) : (
              <button
                className={style.btn_4}
                onClick={() => setPopState({ ...popState, 4: true })}
              />
            )}
            {popState[3] ? (
              <div className={style.block_3} />
            ) : (
              <button
                className={style.btn_3}
                onClick={() => setPopState({ ...popState, 3: true })}
              />
            )}
            {popState[2] ? (
              <div className={style.block_2} />
            ) : (
              <button
                className={style.btn_2}
                onClick={() => setPopState({ ...popState, 2: true })}
              />
            )}
            {popState[1] ? (
              <div className={style.block_1} />
            ) : (
              <button
                className={style.btn_1}
                onClick={() => setPopState({ ...popState, 1: true })}
              />
            )}
            <div className={style.pop_ttl2} />
            <div className={style.pop_ttl1} />
            <button
              className={style.btn_popup_close_2}
              onClick={() => setSlidePop(0)}
            />
          </div>
        )}

        {slidePop == 1 && (
          <div className={style.popupWrapper}>
            <div className={style.rec} />
            <div className={style.pop1_txt} />
            <button
              className={style.btn_popup_close_1}
              onClick={() => setSlidePop(0)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Slide_19;
