import style from "./neo_modul_015_2409.module.scss";
import { useState } from "react";
import cn from "classnames";

const Slide_15 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slideState, setSlideState] = useState(0);
  const [slidePop, setSlidePop] = useState(0);
  const [goNextActive, setGoNextActive] = useState({
    1: false,
    2: false,
  });

  return (
    <div className={cn(style.neo_modul_015_2409)}>
      <div className="scale-wrapper">
        <div className={style.st1_back_left} />
        <div className={style.st1_back_right} />
        <div className="cond-block">
          {slideState === 2 && (
            <>
              <div className={style.bg_st2} />
              <div className={cn(style.img, "fadeIn dur1000 del400")} />
              <div className={cn(style.txt_1, "fadeIn dur1000 del400")} />
              <div className={style.title_2} />
              <div className={style.btn_2_on} />
              <button
                className={style.btn_1_off}
                onClick={() => setSlideState(1)}
              />
              <div className={cn(style.txt_2, "fadeIn dur1000 del400")} />
            </>
          )}

          {slideState === 1 && (
            <>
              <div className={style.title_bl_1} />
              <button
                className={style.btn_2_off}
                onClick={() => {
                  setSlideState(2);
                  setGoNextActive((prev) => ({ ...prev, 1: true }));
                }}
              />
              <div className={style.btn_1_on} />
              <div className={cn(style.txt_2_1, "fadeIn dur1000 del400")} />
              <div className={cn(style.txt_1_1, "fadeIn dur1000 del400")} />
              <div className={style.cross} />
              <button
                className={style.btn_pop_1}
                onClick={() => {
                  setSlidePop(1);
                  setGoNextActive((prev) => ({ ...prev, 2: true }));
                }}
              />
            </>
          )}

          <div className={style.note_1_1} />

          {slideState === 0 && (
            <>
              <div className={style.fade_right} />
              <div className={style.txt_2_0} />
              <div className={style.fade_left} />
              <div className={style.txt_1_0} />

              <button
                className={style.cross}
                onClick={() => setSlideState(1)}
              />
              <div className={style.help_info} />
              <div className={style.title_0} />
              <div className={style.note_0} />
            </>
          )}
        </div>
        <div className={style.btn_go_off} data-state="0" />
        {goNextActive[1] && goNextActive[2] && (
          <button className={style.btn_go_on} onClick={goNextSlide} />
        )}
        <button className={style.btn_back_on} onClick={goPrevSlide} />
        <div className={style.timeline} />

        <div className="popup-block">
          {slidePop && (
            <div data-state="0" className={style.popupWrapper}>
              <div className={style.popup_base} />
              <div className={style.txt_pop} />
              <button
                className={style.btn_popup_close_1}
                onClick={() => {
                  setSlidePop(0);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Slide_15;
