import style from "./neo_modul_014_2409.module.scss";
import { useState } from "react";
import cn from "classnames";
import "rheostat/initialize";
import Rheostat from "rheostat";

const Slide_14 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slideState, setSlideState] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });
  const [sliderValue, setSliderValue] = useState(0);
  const [goNextActive, setGoNextActive] = useState({
    1: false,
    2: false,
  });

  const [slidePop, setSlidePop] = useState(0);
  const updateValue = (sliderValue) => {
    let [value] = sliderValue.values;
    if (value === 5) {
      setGoNextActive({ ...goNextActive, 1: true });
    }
    setSliderValue(value);
  };

  return (
    <div className={cn(style.neo_modul_014_2409)}>
      <div className={style.scaleWrapper}>
        {slideState[5] ? (
          <div className={cn(style.block_5, "fadeIn dur1000")} />
        ) : (
          <button
            className={style.btn_5}
            onClick={() => setSlideState({ ...slideState, 5: true })}
          />
        )}
        {slideState[4] ? (
          <div className={cn(style.block_4, "fadeIn dur1000")} />
        ) : (
          <button
            className={style.btn_4}
            onClick={() => setSlideState({ ...slideState, 4: true })}
          />
        )}
        {slideState[3] ? (
          <div className={cn(style.block_3, "fadeIn dur1000")} />
        ) : (
          <button
            className={style.btn_3}
            onClick={() => setSlideState({ ...slideState, 3: true })}
          />
        )}
        {slideState[2] ? (
          <div className={cn(style.block_2, "fadeIn dur1000")} />
        ) : (
          <button
            className={style.btn_2}
            onClick={() => setSlideState({ ...slideState, 2: true })}
          />
        )}
        {slideState[1] ? (
          <div>
            <div className={cn(style.block_1, "fadeIn dur1000")} />
            <button
              className={cn(style.btn_info, "fadeIn dur1000")}
              onClick={() => {
                setSlidePop(1);
              }}
            />
          </div>
        ) : (
          <button
            className={style.btn_1}
            onClick={() => setSlideState({ ...slideState, 1: true })}
          />
        )}
        {sliderValue === 0 ? <div className={style.help_info} /> : null}
        <div className={style.line} />
        <div
          className={sliderValue === 5 ? style.num_5_active : style.num2013}
        ></div>
        <div
          className={sliderValue === 4 ? style.num_4_active : style.num2012}
        ></div>
        <div
          className={sliderValue === 3 ? style.num_3_active : style.num2010}
        ></div>
        <div
          className={sliderValue === 2 ? style.num_2_active : style.num2006}
        ></div>
        <div
          className={sliderValue === 1 ? style.num_1_active : style.num2004}
        ></div>
        {sliderValue === 5 ? (
          <div>
            <div className={style.timeline_txt5}></div>
            <div className={style.timeline_txt4}></div>
            <div className={style.timeline_txt3}></div>
            <div className={style.timeline_txt2}></div>
            <div className={style.timeline_txt1}></div>
          </div>
        ) : null}
        {sliderValue === 4 ? (
          <div>
            <div className={style.timeline_txt4}></div>
            <div className={style.timeline_txt3}></div>
            <div className={style.timeline_txt2}></div>
            <div className={style.timeline_txt1}></div>
          </div>
        ) : null}
        {sliderValue === 3 ? (
          <div>
            <div className={style.timeline_txt3}></div>
            <div className={style.timeline_txt2}></div>
            <div className={style.timeline_txt1}></div>
          </div>
        ) : null}
        {sliderValue === 2 ? (
          <div>
            <div className={style.timeline_txt2}></div>
            <div className={style.timeline_txt1}></div>
          </div>
        ) : null}
        {sliderValue === 1 ? (
          <div>
            <div className={style.timeline_txt1}></div>
          </div>
        ) : null}
        <div className={style.scr_line} />
        <div className={style.slider}>
          <Rheostat
            orientation={"horizontal"}
            min={0}
            max={5}
            values={[sliderValue]}
            onValuesUpdated={updateValue}
          />
        </div>
        <div className={style.title} />
        <div className={style.timeline} />
        <div className={style.note} />
        <div className={style.btn_go_off} />
        <div className={style.btn_back_on} onClick={goPrevSlide} />
        {goNextActive[1] &&
          goNextActive[2] &&
          slideState[1] &&
          slideState[2] &&
          slideState[3] &&
          slideState[4] &&
          slideState[5] && (
            <button className={style.btn_go_on} onClick={goNextSlide} />
          )}
        <div className="popup-block">
          {slidePop == 1 && (
            <>
              <div className={style.popupWrapper}>
                <div className={style.popup_content} />
                <button
                  className={style.btn_close}
                  onClick={() => {
                    setSlidePop(0);
                    setGoNextActive({ ...goNextActive, 2: true });
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Slide_14;
