import style from "./neo_modul_007_2409.module.scss";
import { useState } from "react";
import cn from "classnames";

const Slide_7 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slideState, setSlideState] = useState(0);
  const [slidePop, setSlidePop] = useState(0);
  const [goNextActive, setGoNextActive] = useState(false);

  return (
    <div className={cn(style.neo_modul_007_2409)}>
      <div className={style.scaleWrapper}>
        <div className={style.timeline} />
        <div className={style.note} />
        <div className={style.btn_go_off} data-state="0" />
        <button className={style.btn_back_on} onClick={goPrevSlide} />
        <div className={style.condBlock}>
          {slideState == 1 && (
            <>
              {" "}
              <div className={style.condWrapper}></div>
              <div className={cn(style.ellipse_wh, "fadeIn dur800 del400")} />
              <div className={style.line} />
              <div
                className={cn(style.block_icons, "fadeInDown dur800 del600")}
              />
              <div className={style.logo_black} />
              <div className={style.src} />
              <button
                className={style.btn_info}
                onClick={() => {
                  setSlidePop(1);
                  setGoNextActive(true);
                }}
              />
            </>
          )}

          {slideState === 0 && (
            <>
              {" "}
              <div className={style.condWrapper}></div>
              <div className={style.ellipse_bl} />
              <div className={cn(style.logo_white, "fadeIn dur800 del400")} />
              <div
                className={cn(style.help_info, "jackInTheBox dur800 del400")}
              />
              <button className={style.line} onClick={() => setSlideState(1)} />
            </>
          )}
        </div>
        <div className={cn(style.title, "fadeInLeft dur800 del400")} />
        {goNextActive && (
          <button className={style.btn_go_on} onClick={goNextSlide} />
        )}

        <div className={style.popupBlock}>
          {slidePop === 1 && (
            <div className={style.popupWrapper}>
              <div className={style.popup_content} />
              <button
                className={style.btn_popup_close_1}
                onClick={() => setSlidePop(0)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Slide_7;
