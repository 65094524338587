import { useState } from "react";
// import Slide from '../../Components/Slide/Slide.js';
import style from "./neo_modul_018_2409.module.scss";
import cn from "classnames";

const Slide_18 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slideState, setSlideState] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });
  const [popState, setPopState] = useState({
    1: false,
    2: false,
    3: false,
  });
  const [slidePop, setSlidePop] = useState(0);
  return (
    <div className={cn(style.neo_modul_018_2409)}>
      <div className={style.scaleWrapper}>
        {slideState[5] ? (
          <div className={cn(style.ing_5, "fadeIn dur1000 ")} />
        ) : (
          <button
            className={style.btn_5}
            onClick={() => setSlideState({ ...slideState, 5: true })}
          />
        )}
        {slideState[4] ? (
          <div className={cn(style.ing_4, "fadeIn dur1000 ")} />
        ) : (
          <button
            className={style.btn_4}
            onClick={() => setSlideState({ ...slideState, 4: true })}
          />
        )}
        {slideState[3] ? (
          <div className={cn(style.ing_3, "fadeIn dur1000 ")} />
        ) : (
          <button
            className={style.btn_31}
            onClick={() => setSlideState({ ...slideState, 3: true })}
          />
        )}
        {slideState[2] ? (
          <div className={cn(style.ing_2, "fadeIn dur1000 ")} />
        ) : (
          <button
            className={style.btn_21}
            onClick={() => setSlideState({ ...slideState, 2: true })}
          />
        )}
        {slideState[1] ? (
          <div className={cn(style.ing_1, "fadeIn dur1000 ")} />
        ) : (
          <button
            className={style.btn_11}
            onClick={() => setSlideState({ ...slideState, 1: true })}
          />
        )}

        <div className={style.img} />
        <div className={style.title_rect} />
        <div className={style.title} />
        <div className={style.timeline} />
        <div className={style.note} />
        <button className={style.btn} onClick={() => setSlidePop(1)} />
        <div className={style.btn_go_off} />
        {slideState[1] &&
          slideState[2] &&
          slideState[3] &&
          slideState[4] &&
          slideState[5] &&
          popState[1] &&
          popState[2] &&
          popState[3] && (
            <button className={style.btn_go_on} onClick={goNextSlide} />
          )}
        <button className={style.btn_back_on} onClick={goPrevSlide} />

        {/* <div className={style.popup-block} /> */}
        {slidePop == 1 && (
          <div className={style.popupWrapper}>
            <div className={style.pop_back} />
            <div className={style.pop_graph} />
            {popState[3] ? (
              <div className={style.block_3} />
            ) : (
              <button
                className={style.btn_3}
                onClick={() => setPopState({ ...popState, 3: true })}
              />
            )}
            {popState[2] ? (
              <div className={style.block_2} />
            ) : (
              <button
                className={style.btn_2}
                onClick={() => setPopState({ ...popState, 2: true })}
              />
            )}
            {popState[1] ? (
              <div className={style.block_1} />
            ) : (
              <button
                className={style.btn_1}
                onClick={() => setPopState({ ...popState, 1: true })}
              />
            )}
            <div className={style.pop_pack} />
            <div className={style.pop_ttl2} />
            <div className={style.pop_note} />
            <div className={style.pop_ttl1} />
            <button
              className={style.btn_popup_close_1}
              onClick={() => setSlidePop(0)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Slide_18;
