import style from "./neo_modul_009_2409.module.scss";
import { useState } from "react";
import cn from "classnames";

const Slide_9 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;
  const [slideState, setSlideState] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });
  return (
    <div className={cn(style.neo_modul_009_2409)}>
      <div className={style.scaleWrapper}>
        {slideState[4] ? (
          <div className={cn(style.graph_4, "growXWidth dur800 ")} />
        ) : (
          <button
            className={style.btn_4}
            onClick={() => setSlideState({ ...slideState, 4: true })}
          />
        )}
        {slideState[3] ? (
          <div className={cn(style.graph_3, "growXWidth dur800 ")} />
        ) : (
          <button
            className={style.btn_3}
            onClick={() => setSlideState({ ...slideState, 3: true })}
          />
        )}
        {slideState[2] ? (
          <div className={cn(style.graph_2, "growXWidth dur800 ")} />
        ) : (
          <button
            className={style.btn_2}
            onClick={() => setSlideState({ ...slideState, 2: true })}
          />
        )}
        {slideState[1] ? (
          <div className={cn(style.graph_1, "growXWidth dur800 ")} />
        ) : (
          <>
            <div className={style.help_info} />

            <button
              className={style.btn_1}
              onClick={() => setSlideState({ ...slideState, 1: true })}
            />
          </>
        )}
        <div className={style.txt} />
        <div className={style.title} />
        <div className={style.timeline} />
        <div className={style.note} />
        <div className={style.btn_go_off} data-store="3" />
        <button className={style.btn_back_on} onClick={goPrevSlide} />
        {slideState[1] && slideState[2] && slideState[3] && slideState[4] && (
          <button className={style.btn_go_on} onClick={goNextSlide} />
        )}
      </div>
    </div>
  );
};
export default Slide_9;
