import Main from "./pages/Main";
import { useEffect, useState, useRef } from "react";
import "./assets/styles/App.scss";
import "./assets/styles/Animate.scss";

function App() {
  const ref = useRef(null);

  return (
    <div className="App" ref={ref}>
      <Main app={ref} />
    </div>
  );
}

export default App;
