import { useEffect, Fragment, useState } from "react";
import "../assets/styles/App.scss";

import Slide_1 from "../slides/neo_modul_001_2409/neo_modul_001_2409.js";
import Slide_2 from "../slides/neo_modul_002_2409/neo_modul_002_2409.js";
import Slide_3_1 from "../slides/neo_modul_003_1_2409/neo_modul_003_2409.js";
import Slide_3_2 from "../slides/neo_modul_003_2_2409/neo_modul_003_2409.js";
import Slide_3_3 from "../slides/neo_modul_003_3_2409/neo_modul_003_2409.js";
import Slide_3_4 from "../slides/neo_modul_003_4_2409/neo_modul_003_2409.js";
import Slide_4 from "../slides/neo_modul_004_2409/neo_modul_004_2409.js";
import Slide_5 from "../slides/neo_modul_005_2409/neo_modul_005_2409.js";
import Slide_6 from "../slides/neo_modul_006_2409/neo_modul_006_2409.js";
import Slide_7 from "../slides/neo_modul_007_2409/neo_modul_007_2409.js";
import Slide_8 from "../slides/neo_modul_008_2409/neo_modul_008_2409.js";
import Slide_9 from "../slides/neo_modul_009_2409/neo_modul_009_2409.js";
import Slide_10 from "../slides/neo_modul_010_2409/neo_modul_010_2409.js";
import Slide_11 from "../slides/neo_modul_011_2409/neo_modul_011_2409.js";
import Slide_12 from "../slides/neo_modul_012_2409/neo_modul_012_2409.js";
import Slide_13 from "../slides/neo_modul_013_2409/neo_modul_013_2409.js";
import Slide_14 from "../slides/neo_modul_014_2409/neo_modul_014_2409.js";
import Slide_15 from "../slides/neo_modul_015_2409/neo_modul_015_2409.js";
import Slide_16 from "../slides/neo_modul_016_2409/neo_modul_016_2409.js";
import Slide_17 from "../slides/neo_modul_017_2409/neo_modul_017_2409.js";
import Slide_18 from "../slides/neo_modul_018_2409/neo_modul_018_2409.js";
import Slide_19 from "../slides/neo_modul_019_2409/neo_modul_019_2409.js";
import Slide_20 from "../slides/neo_modul_020_2409/neo_modul_020_2409.js";
import Slide_21 from "../slides/neo_modul_021_2409/neo_modul_021_2409.js";
import Slide_22 from "../slides/neo_modul_022_2409/neo_modul_022_2409.js";
import Slide_23 from "../slides/neo_modul_023_2409/neo_modul_023_2409.js";
import Slide_24 from "../slides/neo_modul_024_2409/neo_modul_024_2409.js";
import Slide_25 from "../slides/neo_modul_025_2409/neo_modul_025_2409.js";
import Slide_26 from "../slides/neo_modul_026_2409/neo_modul_026_2409.js";
import Slide_27 from "../slides/neo_modul_027_2409/neo_modul_027_2409.js";
import Slide_28 from "../slides/neo_modul_028_2409/neo_modul_028_2409.js";
import Slide_29 from "../slides/neo_modul_029_2409/neo_modul_029_2409.js";
import Slide_30 from "../slides/neo_modul_030_2409/neo_modul_030_2409.js";
import Slide_31 from "../slides/neo_modul_031_2409/neo_modul_031_2409.js";
import Slide_32 from "../slides/neo_modul_032_2409/neo_modul_032_2409.js";
function doScale() {
  const mode = "save_proportions";
  if (mode === "no_scale") {
    console.log(
      `Чтобы акивировать скейл слайдов необходимо в пресконфиге в объекте config определить поле scale и выбрать одну из опций:
      save_proportions - маcштабирует слайд, но сохраняет его пропорции;
      scale_to_edges - растягивает слайд на весь экран;
`
    );
    return;
  }
  const slide = document.querySelector(".slide");
  const slideWidth = slide.clientWidth;
  const slideHeight = slide.clientHeight;
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  let options;
  if (mode === "save_proportions") {
    slide.style.top = "50%";
    slide.style.left = "50%";
    options = "translate(-50%, -50%)";

    if (windowHeight < slideHeight) {
      options = `scaleX(${
        (windowHeight / slideWidth) * (slideWidth / slideHeight)
      }) scaleY(${windowHeight / slideHeight}) translate(-50%, -50%)`;
    }
    if (
      windowWidth < slideWidth &&
      windowWidth / windowHeight < slideWidth / slideHeight
    ) {
      options = `scaleX(${windowWidth / slideWidth}) scaleY(${
        windowWidth / (slideHeight * (slideWidth / slideHeight))
      }) translate(-50%, -50%)`;
    }
  }

  slide.style.transform = options;
}
const Main = ({ app }) => {
  const [currentStep, setStep] = useState(0);

  const goTo = {
    goNextSlide: function () {
      setStep((prev) => prev + 1);
    },
    goPrevSlide: function () {
      setStep((prev) => prev - 1);
    },
  };
  const slides = {
    0: <Slide_1 goTo={goTo} />,
    1: <Slide_2 goTo={goTo} />,
    2: <Slide_3_1 goTo={goTo} />,
    3: <Slide_4 goTo={goTo} />,
    4: <Slide_5 goTo={goTo} />,
    5: <Slide_6 goTo={goTo} />,
    6: <Slide_3_2 goTo={goTo} />,
    7: <Slide_7 goTo={goTo} />,
    8: <Slide_8 goTo={goTo} />,
    9: <Slide_9 goTo={goTo} />,
    10: <Slide_3_3 goTo={goTo} />,
    11: <Slide_10 goTo={goTo} />,
    12: <Slide_11 goTo={goTo} />,
    13: <Slide_3_4 goTo={goTo} />,
    14: <Slide_12 goTo={goTo} />,
    15: <Slide_13 goTo={goTo} />,
    16: <Slide_14 goTo={goTo} />,
    17: <Slide_15 goTo={goTo} />,
    18: <Slide_16 goTo={goTo} />,
    19: <Slide_17 goTo={goTo} />,
    20: <Slide_18 goTo={goTo} />,
    21: <Slide_19 goTo={goTo} />,
    22: <Slide_20 goTo={goTo} />,
    23: <Slide_21 goTo={goTo} />,
    24: <Slide_22 goTo={goTo} />,
    25: <Slide_32 goTo={goTo} />,
    26: <Slide_23 goTo={goTo} />,
    27: <Slide_24 goTo={goTo} />,
    28: <Slide_25 goTo={goTo} />,
    29: <Slide_26 goTo={goTo} />,
    30: <Slide_27 goTo={goTo} />,
    31: <Slide_28 goTo={goTo} />,
    32: <Slide_29 goTo={goTo} />,
    33: <Slide_30 goTo={goTo} />,
    34: <Slide_31 goTo={goTo} />,
  };

  const [scale, setScale] = useState(1);

  useEffect(() => {
    doScale();
    const eventList = ["DOMContentLoaded", "resize", "orientationchange"];
    eventList.forEach((event) => window.addEventListener(event, doScale));

    return () => {
      eventList.forEach((event) => window.removeEventListener(event, doScale));
    };
  }, [currentStep]);
  return (
    <Fragment>
      <div className="slide">
        {slides[currentStep]}
        <button className="btn_home" onClick={() => setStep(1)} />
      </div>
    </Fragment>
  );
};

export default Main;
