// import React from 'react';
// import Slide from '../../Components/Slide/Slide.js';
// import "./neo_modul_003_2409.module.scss";
import style from "./neo_modul_003_2409.module.scss";
import cn from "classnames";

const Slide_3_1 = ({ goTo }) => {
  const { goNextSlide, goPrevSlide } = goTo;

  return (
    <div className={cn(style.neo_modul_003_2409)}>
      <div className={style.scaleWrapper}>
        <div className={cn(style.btn_4, "fadeInUp dur800 del400")} />
        <div className={cn(style.btn_3, "fadeInDown dur800 del400")} />
        <div className={cn(style.btn_2, "fadeInUp dur800 del400")} />
        <button
          className={cn(style.btn_1, "fadeInDown dur800 del400")}
          onClick={goNextSlide}
        />
        <div className={cn(style.help_info, "jackInTheBox dur800 del400")} />
        <div className={style.title} />
        <div className={style.timeline} />
        <button className={style.btn_back_on} onClick={goPrevSlide} />
        <div className={style.btn_go_off} />
      </div>
    </div>
  );
};

export default Slide_3_1;
